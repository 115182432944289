h2 {
  font-family: 'Zilla Slab', serif;
  font-size: 1.5em;
  color: #45D0A7;
}

h3 {
  font-family: 'Zilla Slab', serif;
  font-size: 1.3em;
}

h4 {
  font-size: 0.85em;
}

h5 {
  font-family: 'Zilla Slab', serif;
  font-size: 1em;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9em;
}

a {
  text-decoration: none !important;
  font-family: 'Zilla Slab', serif;
  font-size: 1em;
}

.image--download, .image--view, .little__box--skill, .contact, .cv__info, .cv__info--school{
  box-shadow: 0 1px 2px rgba(0,0,0,0.07),
  0 2px 4px rgba(0,0,0,0.07),
  0 4px 8px rgba(0,0,0,0.07),
  0 8px 16px rgba(0,0,0,0.07),
  0 8px 16px rgba(0,0,0,0.07),
  0 1px 2px rgba(0,0,0,0.07);
}
/* ---------------------- MENU ------------------------ */
.navbar-light {
  box-shadow: 0 1px 2px rgba(0,0,0,0),
  0 2px 4px rgba(0,0,0,0.0),
  0 2px 8px rgba(0,0,0,0.3)
}


.navbar-nav {
  margin: 0 auto 0 auto;
}

.navbar__link--decal {
  padding-left: 4em;
}
.nav-item {
  padding-right: 0.8em;
}

.nav-realisation {
  margin-right: 5em;
}

.navbar__btn { //contact-me
  float: right;
  background-color: #45D0A6FF;
  border-radius: 1.2em;
  font-size: 12px;
  color: #FFF;
  text-transform: uppercase;
  padding: 0.5em 1em;
}

.navbar__btn:hover {
  text-decoration: none;
  background-color: #07c499;
  color: #FFF;
}

/*--------------------------------- Component ---------------------------------------------*/

/*----------------------- Presentation  -------------------------*/

.presentation {
  margin-top: 8em;
}

.presentation__photo {
  float: right;
  margin-right: 12em;
  width: 8em;
}

.presentation__name {
  color: #07c499;
}

.presentation__paragraph {
  color: #29324E;
}

/* ------------------------------------ CV  ------------------------------------- */

.before__container {
  margin-top: 3em;
  width: auto;
  border-bottom: 50px solid #f8f9fa;
  border-right: 100vw solid transparent;
}

.after__container {
  width: auto;
  border-top: 50px solid #f8f9fa;
  border-left: 100vw solid transparent;
}

.container__cv {
  background-color: #f8f9fa;
}

.cv {
  padding: 2em 0;
}

.cv__title {
  color: #07c499;
}

.mr--title {
  margin-right: 4em;
}

.cv__left {
  margin-top: 2.5em;
  width: 58%;
  //padding-left: 5em;
  text-align: center;
}

.cv__image {
  margin-top: 0;
  width: 100%;
}

.image--view {
  margin-top: 1.5em;
  height: 58%;
  width: 58%;
}

.button__download {
  width: 58%;
}

.image--download {
  color: #FFFFFF;
  padding: 0.6em 1.1em;
  border-width: 0;
  border-radius: 1.2em;
  letter-spacing: 2px;
  font-size: 11px;
  font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif!important;
  font-weight: 800;
  text-transform: uppercase;
  background-color: #45D0A6FF;
  //margin-left: 6em;
}

.image--download:hover {
  color: #FFFFFF;
  background-color: #07c499;
}

.cv__info {
  background-color: #45D0A6FF;
  margin: 0.7em 0;
  padding: 0.8em 0.8em;
  width: 12em;
  min-height: 5em;
}

.cv__info--school {
  background-color: #45D0A6FF;
  margin: 0.7em 0;
  padding: 1.5em 1em;
  width: 12em;
  height: 5em;
}

.cv__icon {
  font-size: 1.25em;
  position: relative;
  left: 8.9em;
  bottom: 5em;
  padding-top: 0.6em;
}

.cv__icon--first {
  padding-top: 0.75em;
}

.info__date--school {
  padding-left: 1.5em;
  font-size: 0.9em;
  font-weight: 500;
}

.info__title {
  font-size: 1em;
  padding-top: 0.2em;
  vertical-align: center;
  text-align: center;
}

.info__date {
  font-style: italic;
  text-align: center;
}

.cv__description {
  padding-top: 0.8em;
}

.description__paragraph {
  line-height: 1.2em;
  margin-bottom: 0;
}

/* --------------------------- Skills --------------------------*/

.bar--vertical {
  border-radius: 1em;
  height: 100%;
  width: 0.2em;
  background: #45D0A7;
}

.skills {
  margin-top: 1.5em;
}

.skills__title {
  color: #45D0A7;
}

.skills__subtitle {
  font-size: 1em;
  text-align: center;
  padding-right: 2.8em;
}

.subtitle--front {
  padding-right: 7.2em;
}

.subtitle--left {
  padding-right: 8.5em;
}

.skills__icon {
  padding-left: 1em;
  position: relative;
  top: 0.25em;
  color: #484f5a;
  font-size: 1.5em;
}

.block__front {
  margin-top: 2em;
  margin-right: 8em;
}

.skills__logo--front {
  height: 3.2em;
}

.skills__logo--front:hover {
  cursor: pointer;
}

[data-aos="circle-outside"] {
  transform: rotate(0deg);
  transition-property: transform;
  &.aos-animate {
    transform: rotate(360deg)
  }
}

[data-aos="circle-inside"] {
  transform: rotate(0deg);
  transition-property: transform;
  &.aos-animate {
    transform: rotate(-360deg);
  }
}

.front__two {
  position: relative;
  top: -1.6em;
  margin-left: 6.5em;
}

.front__tree {
  position: relative;
  top: -1.45em;
  margin-right: 6.5em;
}

.front__four {
  position: relative;
  top: -0.65em;
  margin-left: 3.6em;
}

.front__five {
  position: relative;
  top: -0.4em;
  margin-right: 4em;
  height: 2.8em;
}

.front__six {
  position: relative;
  top: 0.8em;
  margin-left: 9.3em;
}

.front__seven {
  position: relative;
  top: 0.8em;
  margin-right: 9.3em;
}

.block__back {
  margin: 5.5em 4em 0 4em;
}

.skills__logo--back {
  height: 3.2em;
}

.back__one {
  margin-left: 3em;
}

.back__js {
  margin-top: 2em;
}

.back__two {
  margin-right: 1em;
}

.back__tree {
  margin-top: 0.2em;
}

.little__box--skill {
  padding: 0.5em 1.5em;
  border-radius: 0.5em;
  background: #FFFFFF;
  min-height: 4.6em;
  height: auto;
  width: 11em;
}

/* -------------------------- PROJECT -------------------------- */

.container__project {
  margin-top: 4em;
  padding-bottom: 2em;
  background-color: #f8f9fa;
}

/******************* CAROUSEL ********************/


/* Arrow */

.slick-prev, .slick-next {
  position: absolute;
  z-index: 100;
}

.slick-next:before,
.slick-prev:before {
  content: "" !important;
}
.next-slick-arrow,
.prev-slick-arrow {
  position: absolute;
  color: #45D0A7;
  font-size: 48px;
}

.next-slick-arrow:hover,
.prev-slick-arrow:hover {
  color: #07c499;
}

.prev-slick-arrow {
  left: 0.8em;
}

.next-slick-arrow {
  right: 0.8em;
}

/* end Arrow */

.slider-wrapper {
  width: 760px;
  margin: auto;
}
.slick-slide {
  text-align: center;
  position: relative;
}

.slick-slide:hover {
  cursor: pointer;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide-title {
  color: #212529;
  text-transform: capitalize;
  margin-top: 1em;
}
.slick-slide-image {
  position: relative;
  margin: 0 auto;
  max-width: 80%;
  z-index: 1;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
  0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}
.slick-slide-label {
  background-color: rgba(72, 79, 90, 0.7);
  color: #FFF;
  padding: 10px;
  position: absolute;
  z-index: 10;
  left: 10%;
  font-size: 1.1em;
  font-family: 'Zilla Slab', serif;
  font-weight: 500;
  bottom: 0;
  width: 80%;
}

.thumbnail-slider-wrap {
  margin: 2em auto 0 auto;
  width: 70%;
}
.slick-prev:before,
.slick-next:before {

  color: #777777;
}

/************** END CAROUSEL *****************/

/*************************** DETAIL PROJECT ************************/

.detail {
  margin-bottom: 2em;
}

.detail--dark {
  background-color: rgba(72, 79, 90, 0.7);
  margin: 3em 1em;
}

.detail--light {
  background-color: #f8f9fa;
  margin: 3em 1em;
}

.detail__info {
  background-color: #FFFFFF;
  padding: 1em 2em;
  height: 91.5%;
  @media screen and (max-width: 1200px) {
    height: 89%;
  }
  @media screen and (max-width: 992px) {
    height: 87%;
  }
  @media screen and (max-width: 768px) {
    height: 89%;
  }
}

.detail__close {
  position: absolute;
  right: 2em;
  top: 2em;
  font-size: 2em;
  color: #FFFFFF;
}

.detail__close:hover {
  color: #E23237;
  cursor: pointer;
}

hr {
  background-color: #07c499;
  height: 0.1em;
}

.detail__img {
  width: 100%;
  margin-bottom: 2em;
}

/* ---------------------- Contact ------------------------ */

.contact {
  background-color: #F6F7F9;
  padding: 0 3em 2em 3em;
  max-width: 54em;
  border-radius: 1.5em;
  margin: 0 auto 3em auto;
}

.alert-message {
  width: 40%;
  background-color: #45D0A6FF;
  border-radius: 8px;
  padding: 0.3em 2em;
}

.contact__error {
  font-style: italic;
  color: red;
}

.contact__info {
  margin-left: 1em;
}

.contact__hr--first {
  margin-top: 2em;
}

.info--icon {
  position: relative;
  top: 0.15em;
  font-size: 25px;
  padding-right: 0.5em;
}

.contact__email {
  color: #07c499;
  font-size: 18px;
  font-weight: bold;
  padding-top: 1em;
}

.contact__phone {
  color: #07c499;
  font-size: 18px;
  font-weight: bold;
  padding-top: 1em;
}

.contact__btn {
  border: 0;
  border-radius: 0.5em;
  background-color: #45D0A7;
  padding: 0.35em 4em;
}

.contact__btn:hover {
  background-color: #07c499;
}

/* ------------------- ANIMATION ------------------------ */

.animatable {

  /* initially hide animatable objects */
  visibility: hidden;

  /* initially pause animatable objects their animations */
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -ms-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

/* show objects being animated */
.animated {
  visibility: visible;

  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

/*--------------- END  ANIMATION ANIMATABLE --------------------*/

/*--------------------------MEDIA QUERY ------------------------*/

@media (max-width: 1399.99px) {
  /*---------------- SKILLS ---------------*/
  .front__two {
    margin-left: 4em;
  }

  .front__tree {
    margin-right: 4em;
  }

  .front__four {
    margin-left: 1.6em;
  }

  .front__five {
    margin-right: 2em;
  }

  .front__six {
    margin-left: 7.8em;
  }

  .front__seven {
    position: relative;
    top: 0.8em;
    margin-right: 7.8em;
  }

  .back__one {
    margin-right: 4em;
  }
}



@media (max-width: 1199.98px) {
  /* ---------------- CV  ------------- */

  .cv__icon {
    font-size: 1.25em;
    position: relative;
    left: 9em;
    bottom: 4.8em;
  }

  .cv__left {
    margin: 15vh 0 0 1em;
    text-align: center;
  }

  .image--view {
    margin-left: 1.4em;
  }

  .cv__description {
    margin-left: 1em;
  }

  .image--download {
    margin-left: 15%;
  }

  /*---------------- SKILLS ---------------*/

  .back__one {
    margin-right: 4em;
  }

  .front__two {
    margin-left: 1.5em;
  }

  .front__tree {
    margin-right: 1.5em;
  }

  .front__four {
    margin-left: -0.8em;
  }

  .front__five {
    margin-right: -0.4em;
  }

  .front__six {
    margin-left: 4.8em;
  }

  .front__seven {
    margin-right: 4.8em;
  }
}

@media (max-width: 991.98px) {
  /*----------- PRESENTATION ------------*/

  .presentation__photo {
    float: right;
    margin-right: 1em;
  }

  /* ---------------- CV  ------------- */

  .mr--title {
    margin-right: 1.5em;
  }

  .cv__left {
    margin-top: 15vh;
    text-align: center;
    width: 92%;
  }

  .cv__description {
    padding-left: 2.5em;
  }

  .image--view {
    margin-top: 1.5em;
    margin-left: 15%;
    height: 80%;
    width: 80%;
  }

  .button__download {
    width: 78% !important;
    margin-left: 15%;
  }

  .image--download {
    margin: 0 0 0 0;
    padding: 0.6em 1.1em;
    font-size: 10px;
  }

  .cv__info {
    background-color: #45D0A6FF;
    margin: 0.7em 0;
    padding: 0.8em 0.8em;
    width: 12em;
    min-height: 5em;
  }

  /*---------------- SKILLS ---------------*/

  .subtitle--front {
    padding-right: 0.5em;
  }

  .subtitle--back {
    padding-right: 1.3em;
  }

  .block__back {
    margin: 5.5em 0 0 0;
  }

  .block__front {
    margin-right: 5em;
    margin-left: auto;
    width: 20em;
  }

  .front__two {
    margin-left: 1em;
  }

  .front__tree {
    margin-right: 1em;
  }

  .front__four {
    margin-left: -1.5em;
  }

  .front__five {
    margin-right: -1.1em;
  }

  .front__six {
    margin-left: 4.5em;
  }

  .front__seven {
    margin-right: 4.5em;
  }

  /* -------------------------- PROJECT -------------------------- */

  /******************* CAROUSEL ********************/

  .slider-wrapper {
    width: 660px;
    margin: auto;
  }

  /************** END CAROUSEL *****************/

  /* -------------------------- Contact -------------------------- */

  .contact__email,.contact__phone {
    font-size: 14px;
  }

  .info--icon {
    font-size: 20px;
  }

}


@media (max-width: 767.98px) {

  /* --------- MENU -----------*/

  .navbar__link--decal {
    padding-left: 0;
  }
  .nav-item {
    padding-right: 0;
  }

  .nav-realisation {
    margin-right: 0;
  }
  .nav-link {
    text-align: center !important;
  }

  .navbar__btn {
    margin: 1em 0;
  }

  /*----------- PRESENTATION ------------*/

  .container__photo {
    display: flex;
    justify-content: center;
  }

  .presentation__name, .presentation__paragraph {
    text-align: center;
  }

  /*-------------- CV ---------------*/

  .cv__left {
    margin-top: 0;
    text-align: center;
  }

  .cv__title {
    padding: 0;
  }

  .cv__description {
    margin-left: 0;
  }

  .mr--title {
    margin-right: 0;
  }

  .mr-5 {
    margin-right: 0 !important;
    display: flex;
    align-items: center;
  }

  .image--view {
    margin: 1.5em auto 0 auto;
    max-height: 24em;
    width: auto;
  }

  .image--download {
    padding: 0.6em 1.1em;
    font-size: 11px;
    margin: 0 auto;
  }

  .button__download {
    margin: 0 auto;
    width: 58%;
    padding-bottom: 2em;
  }

  .cv__info, .cv__info--school {
    margin: 0 auto;
  }

  .cv__icon {
    font-size: 1.25em;
    position: relative;
    left: 48%;
    bottom: 1.3em;
  }

  .cv__description {
    padding-left: 0;
    padding-right: 0;
  }

  .cv__description h5 {
    position: relative;
    bottom: 1.5em;
    text-align: center;
  }

  .cv__description ul {
    margin: 0 auto;
    padding-left: 2.7em;
    max-width: 15em;
    position: relative;
    bottom: 1em;
  }

  .info__date--school {
    text-align: center;
    padding-left: 0;
  }

  /*---------------- SKILLS ---------------*/

  .subtitle--back {
    padding: 0 ;
  }

  .subtitle--front {
    padding-right: 0;
  }

  .front__seven, .front__six {
    margin-bottom: 3em;
  }

  .block__back {
    margin: 3em 1em 0 1em;
  }

  .block__front {
    margin-right: auto;
    margin-left: auto;
    width: 20em;
  }
  /* -------------------------- CONTACT -------------------------- */

  .alert-message {
    width: 15em;
    margin: 0.5em 0 1em 0;
  }


  /* -------------------------- PROJECT -------------------------- */

  /******************* CAROUSEL ********************/

  .slider-wrapper {
    width: 100%;
    margin: auto;
  }

  .slick-slide-label {
    font-size: 0.85em;
  }

  /************** END CAROUSEL *****************/

  .next-slick-arrow,
  .prev-slick-arrow {
    font-size: 38px;
  }

  .prev-slick-arrow {
    left: 0.5em;
  }

  .next-slick-arrow {
    right: 0.5em;
  }
}

@media (max-width: 500px) {
  .little__box--skill {
    padding: 0.5em 0.7em;
    width: 9em;
  }
}

@media (max-width: 415px) {
  /* -------------------------- CONTACT -------------------------- */
  .alert-message {
    width: 100%;
    margin: 0.5em 0 1em 0;
  }
}
